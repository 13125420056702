

@media (min-width: 1000px) {
    .who-we-are{
        width: 100%;
        margin: 0 auto;
       
    }
    .wwpara , .wwhead{
        text-align: justify;
        padding-left: 80px;
    }
}