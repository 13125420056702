

@media (min-width: 1000px) {
    .Navbar-setup{
        padding-right: 5rem;
        padding-left: 5rem;

    }
}


/* Navbar.css */

.active {
    color: black !important;
  }
  
  .bg-transparent {
    background-color: transparent !important;
  }