.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #084e8e; /* Spinner color */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .book-box:hover {
    --tw-shadow: .625rem .625rem 0 #132c0f;
    --tw-shadow-colored: .625rem .625rem 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}


.loading-message {
  text-align: center;
}

.loading-message p {
  margin-top: 1rem;
}
