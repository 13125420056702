@keyframes typing {
    from { width: 0; }
    to { width: 100%; }
  }
  
  .typing::after {
    content: "|";
    display: inline-block;
    width: 0;
    animation: typing 3s steps(30, end) infinite;
  }
  